import React from "react";
import { Section } from "../../../layout/Section";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import HeadPara from "../../../HeadPara";
import ArticleTag from "../../../ArticleTag";
import AlignmentContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { Flex } from "@chakra-ui/react";
import { Quote } from "../../../texts/Quote";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";
import ArticleQuote from "../../../ArticleQuote";
import QuoteAuthor from "../../../QuoteAuthor";

export const JNSArticle: React.FC = () => {
  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <AlignmentContainer>
          <HeadPara
            large
            para="A virtual experience – The Baltic Sea heritage exhibition"
          />
          <ArticleTags>
            <ArticleTag tag="Customer" value="John Nurminen Foundation" />
            <ArticleTag tag="Project" value="Digital exhibition" />
            <ArticleTag
              tag="What we worked on"
              value="Graphic design, UI/UX design, Concept, Development"
            />
          </ArticleTags>
          <BodyText bold>
            John Nurminen Foundation, a protector of the Baltic Sea, wanted to
            highlight their important work that promotes maritime culture and
            environment into an informative and easy-guided virtual experience. 
          </BodyText>
          <BodyText bold>
            In spring 2023, John Nurminen Foundation initiated the project by
            reaching out to All Things Content, known for their expertise in
            museums and exhibitions. Recognizing the need for concept planning,
            design and development, A.T. Content enlisted Indevit as a partner.
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Insights-JNS-1.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Image: Linus Lindholm, mockup: Julia Glasberg" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara
            medium
            para="Three collaborating entities, each bringing their expertise"
          />
          <BodyText>
            The project launched with three collaborating entities, each
            bringing expertise in their respective fields. Meetings took place
            both onsite and online. Visiting the John Nurminen Foundation
            physical exhibition in Helsinki also provided valuable inspiration
            for the project.
          </BodyText>
          <BodyText>
            <b>John Nurminen Foundation</b> provided visual and textual content
            showcasing the cultural and environmental narrative of the Baltic
            Sea.
          </BodyText>
          <BodyText>
            <b>A.T. Content</b> focused on crafting a coherent narrative from
            John Nurminen Foundation's material, carefully selecting highlights
            and generating written content and translations. Crafting a unified
            narrative from diverse content was no easy task, but A.T. Content
            succeeded admirably.
          </BodyText>
          <BodyText>
            <b>Indevit</b> concentrated on both front and back-end development
            for the virtual exhibition. Indevit handled UI/UX design, concept
            planning, visual content and development, ensuring compatibility
            across various platforms.
          </BodyText>
        </AlignmentContainer>
        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Insights-JNS-2.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Computer image: Linus Lindholm, mockup: Julia Glasberg." />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="The process, from sketch to development" />
          <BodyText>
            Indevit and A.T. Content began by drafting ideas. A prototype was
            created in Figma to visually represent the final concept. Throughout
            the process, John Nurminen Foundation could review and provide
            feedback on layout and functions. The prototype served as a tool
            throughout the project, facilitating communication and ensuring
            alignment on details before coding and development began. It kept
            all parties informed of the progress at all times.
          </BodyText>
          <BodyText>
            Once all parties were satisfied and the final prototype was
            approved, coding for the web application started. The process
            proceeded smoothly, with only a few updates required to the
            pre-coded exhibition.
          </BodyText>
          <BodyText>
            Reflecting on the process from beginning to end, all involved can
            affirm that the end result aligns perfectly with the conceptual
            framework. The exhibition content incorporated diverse features,
            including articles and interactive elements like quizzes and word
            clouds. 
          </BodyText>
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Insights-JNS-3.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Image: Linus Lindholm, mockup: Julia Glasberg" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <ArticleQuote
            body=" “I learned a lot in this collaboration as it was a completely new
            platform for me with unfamiliar tools, possibilities, limitations
            and workflow needs. All this was incredibly rewarding because I love
            learning about and understanding processes. One specific thing I
            will never learn, however, is how to think about content in a
            mobile-friendly format. Fortunately, like everything else about
            UX/UI design, I don't have to learn it because it's in the backbone
            of Indevit.”"
          />
          <QuoteAuthor body=" – Jenny Ingman, All Things Content" />
        </AlignmentContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/Insights-JNS-4.jpg"
            placeholder="none"
          />
          <AlignmentContainer>
            <ImageText text="Computer image: Linus Lindholm, mockup: Julia Glasberg" />
          </AlignmentContainer>
        </ArticleImageContainer>

        <AlignmentContainer>
          <HeadPara medium para="Looking back" />
          <BodyText>
            Every project brings valuable lessons, and this one is no exception!
            Throughout our collaboration, we gained a lot of knowledge about the
            Baltic Sea. Diving in to the cultural and environmental facts was
            truly eye-opening, and the mythical maps of the Baltic Sea stayed in
            our memory. Did you know that old maps were associated with beliefs
            and myths of their time, but they also reflected the political,
            economic, and religious messages of their era?
          </BodyText>
          <BodyText>
            Moreover, the project's emphasis on multilingualism was a noteworthy
            feature. Addressing language barriers (Swedish and Finnish) from the
            outset was essential. Multilingualism not only fostered confidence
            but also encouraged innovative thinking beyond conventional
            boundaries.
          </BodyText>
          <BodyText>
            Working alongside John Nurminen Foundation, the guardian of the
            Baltic Sea, was an absolute pleasure. Their dedication to spreading
            awareness about the sea's importance is admirable, and we are proud
            to have contributed to their mission through this collaboration! 
          </BodyText>
        </AlignmentContainer>

        <AlignmentContainer alignSelf="flex-start" marginTop="6.25rem">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </AlignmentContainer>
      </Flex>
    </Section>
  );
};
