import { Box, Grid, Link, Stack } from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Hero } from "./texts/Hero";
import { BodyText } from "./texts/BodyText";

interface ArticleProp {
  body?: string;
  maxWidth?: string;
}

const ArticleQuote: React.FC<ArticleProp> = ({ body, maxWidth }) => {
  return (
    <BodyText
      fontWeight="400"
      textAlign="center"
      marginTop="100px"
      style={{ fontSize: "24px" }}
    >
      {body}
    </BodyText>
  );
};

export default ArticleQuote;
