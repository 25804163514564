import React from "react";
import { BodyText } from "./texts/BodyText";

interface ArticleProp {
  body?: string;
}

const QuoteAuthor: React.FC<ArticleProp> = ({ body }) => {
  return (
    <BodyText
      textAlign="center"
      marginTop="16px"
      fontSize="16px"
      marginBottom="100px"
    >
      {body}
    </BodyText>
  );
};

export default QuoteAuthor;
