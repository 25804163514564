import React from "react";
import { MetaTags } from "../components/MetaTags";
import { Layout } from "../components/layout/Layout";
import { JNSHero } from "../components/sections/insight/JNS/JNSHero";
import { JNSArticle } from "../components/sections/insight/JNS/JNSArticle";

const JNSPage: React.FC = () => {
  return (
    <Layout
      colorScheme="secondary"
      heroSection={<JNSHero />}
      isHeroShortBaseOnImage
      isWhite
    >
      <JNSArticle />
    </Layout>
  );
};

export default JNSPage;

export const Head = () => <MetaTags title="JNS" />;
